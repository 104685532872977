<!-- =========================================================================================
    File Name: TagsManager.vue
    Description: Менеджер тегов
========================================================================================== -->


<template>
  <vx-card title="Менеджер тегов">
    <div class="mt-4">
      <vs-table 
          :data="data"
          max-items="50"
          pagination
          striped
          hoverFlat
          noDataText="Тегов нет"
          >

        <template slot="thead">
          <vs-th>№</vs-th>
          <vs-th>Наименование</vs-th>
          <vs-th>Дата/время добавления</vs-th>
          <vs-th>ID</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr">{{tr.number}}</vs-td>
            <vs-td :data="tr">{{tr.name}}</vs-td>
            <vs-td :data="tr">{{tr.creation_time}}</vs-td>
            <vs-td :data="tr">{{tr.id}}</vs-td>
          </vs-tr>
        </template>

      </vs-table>

      <div class="flex">
        <div class="flex items-end">
          <vs-input class="w-full" label="Введите наименование нового тега" type="text" v-model="tagName"/>
          <vs-button @click="addTag" size="small" type="border" class="ml-6">
              Добавить тэг
          </vs-button>
        </div>
      </div>
      <br/>
      <div class="flex">
        <i class="vs-icon notranslate icon-scale vs-button--icon  feather icon-info null" style="margin: auto 10px auto 0px;"></i>
        Все наименования тегов сохраняются в базе данных в нижнем регистре. Например, если существует тег "my_tag", то попытка добавить тег "My_tag" вызовет ошибку, и тег не будет добавлен.
      </div>
    </div>
  </vx-card>
</template>


<script>
var qs = require('qs');
export default {
  name: 'tags-manager',
  data() {
    return {
      tagName: '',
      data: [],
      scrollSettings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      }
    }
  },
  methods: {
    async getData() {
      await this.$http({
        method: 'get',
        url: '/udata/users/get_all_tags/.json',
        withCredentials: true
      }).then((response) => {
          if (response.data.tags) {
              this.data = Object.values(response.data.tags);
          } else {
            this.$vs.notify({
              title:'Внимание',
              text: 'Создайте новые теги, чтобы иметь возможность добавлять их к мероприятиям',
              color:'warning',
              iconPack: 'feather',
              icon:'icon-alert-circle'
            })
          }
      })
      .catch((error) => {
        this.$vs.notify({
          title:'Ошибка',
          text: error,
          color:'danger',
          iconPack: 'feather',
          icon:'icon-alert-circle'
        })
      });
    },
    async addTag() {
      let tName = this.getTagName();
      await this.$http({
        method: 'get',
        url: '/udata/users/add_tag/.json' + '?tag_name=' + tName,
        withCredentials: true
      }).then((response) => {
          if (!response.data.error) {
              this.getData();
          } else {
            this.$vs.notify({
              title:'Ошибка',
              text: response.data.error,
              color:'warning',
              iconPack: 'feather',
              icon:'icon-alert-circle'
            })
          }
      })
      .catch((error) => {
        this.$vs.notify({
          title:'Ошибка',
          text: error,
          color:'danger',
          iconPack: 'feather',
          icon:'icon-alert-circle'
        })
      });
    },
    getTagName() {
      return this.tagName;
    }
  },
  mounted() {
    this.getData();
  }
}
</script>
